/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import SEO from "../components/seo"
import ShareButtons from "../components/share-buttons"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const post = data.contentfulNews
  const postLink = encodeURI(
    data.site.siteMetadata.siteUrl +
      data.site.siteMetadata.slugs.news +
      data.contentfulNews.slug +
      "/"
  )
  return (
    <Layout title={false}>
      <SEO
        title={post.title}
        article={true}
        description={post.body.childMarkdownRemark.excerpt}
      />
      <div
        sx={{
          mt: 3,
          display: `flex`,
          flexDirection: `column`,
          textAlign: `center`,
        }}
      ></div>
      <Container sx={{ maxWidth: 690, p: [4, null] }}>
        {post.topic && (
          <h3
            sx={{
              color: `primary`,
              textTransform: `uppercase`,
            }}
          >
            {post.topic}
          </h3>
        )}
        <h1
          sx={{
            maxWidth: 900,
            fontSize: [40, 50],
            color: `text`,
            textTransform: `uppercase`,
          }}
        >
          {post.title}
        </h1>
        {post.subtitle && (
          <h3
            sx={{
              mx: `auto`,
              fontStyle: `italic`,
              fontWeight: 500,
              color: `textMuted`,
            }}
          >
            {post.subtitle}
          </h3>
        )}
        <p>
          <strong>
            {post.place && `${post.place},`} {post.date}
          </strong>
        </p>
        <div
          sx={{ textAlign: `justify` }}
          dangerouslySetInnerHTML={{
            __html: post.body.childMarkdownRemark.html,
          }}
        />
        <ShareButtons title={post.title} url={postLink} />
        <div sx={{ mt: 4, textAlign: `right` }}>
          <h3 sx={{ mb: 1, textTransform: `uppercase` }}>Ufficio Stampa</h3>
          <a href="mailto:media@arturolorenzoni.it">media@arturolorenzoni.it</a>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        slugs {
          news
        }
      }
    }
    contentfulNews(slug: { eq: $slug }) {
      topic
      title
      subtitle
      date(formatString: "DD MMMM YYYY", locale: "it")
      place
      slug
      body {
        childMarkdownRemark {
          excerpt
          html
        }
      }
    }
  }
`
